import "../index.scss";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";
import { sortByOrder } from "../../../helpers/sortByOrder";
import { Radio } from "antd";
import getChoiceTitle from "../../../helpers/getChoiceTitle";
import filterChoices from "../../../helpers/filterChoices";

const SingleChoiceComponent: FC = () => {
  const question = useSelector((state: RootState) => state.questions.question);
  const options = useSelector((state: RootState) =>
    (state.questions.question?.answerTemplate?.answerChoices ?? [])
      .filter((ac) => ac)
      .sort(sortByOrder)
      .map(getChoiceTitle)
      .filter((it) => !!it.choiceId),
  );

  const [selected, setSelected] = useState<number | undefined>();

  // const answer = useSelector((state: RootState) => state.questions.answer);
  const { setUserAnswer } = useActions();

  const onChange = (choiceId: number) => {
    setSelected(choiceId);
    setUserAnswer({ answerValue: "", csiAnswers: [], choices: [choiceId] });
  };

  return (
    <Radio.Group style={{ display: "grid" }} value={selected}>
      {filterChoices(options).map((option, i) => (
        <Radio key={i} value={option.choiceId} style={{ marginBottom: 20 }} onChange={() => onChange(option.choiceId)}>
          <span
            className={question?.surveyDecor?.textFont ? "disable-global-font" : ""}
            style={{ position: "relative", left: 5, fontFamily: question?.surveyDecor?.textFont }}
          >
            {option.label}
          </span>
        </Radio>
      ))}
    </Radio.Group>
  );
};
export default SingleChoiceComponent;
