import "../index.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Radio, Select, Slider, Space, message } from "antd";
import { GradeEnum } from "../../../types/npsParams";
import { NpsTypeEnum } from "../../../types/npsInfoParams";
import Rating from "./rating";
import {I18n, Translate} from "react-redux-i18n";
import { getCurrentTextTranslation } from "../../../helpers/getCurrentTextTranslation";
import { useActions } from "../../../hooks/useActions";

const NpsComponent: FC = () => {
    const { question, npsInfoParams, npsParams } = useSelector((state: RootState) => {
        const question = state.questions?.question;
        const npsInfoParams = question?.answerTemplate?.npsInfoParams;
        return {
            answer: state.questions.answer?.answerValue,
            question: question,
            npsInfoParams: npsInfoParams,
            npsParams: npsInfoParams?.npsParams ?? [],
        };
    });

    const { setUserAnswer } = useActions();
    const [answer, setAnswer] = useState<number | undefined>();

    const onChange = useCallback(
        (answerValue$: string | undefined) => {
            setAnswer(answerValue$ ? Number(answerValue$) : undefined);
            setUserAnswer({ answerValue: answerValue$, csiAnswers: [] });
        },
        [setUserAnswer, setAnswer]
    );

    const isType = useCallback(
        (type: NpsTypeEnum) => {
            return npsInfoParams?.type === type;
        },
        [npsInfoParams?.type]
    );

    useEffect(() => {
        setUserAnswer({
            answerValue: undefined,
            csiAnswers: [],
        });
        return () => {
            setAnswer(undefined);
        };
    }, [question?.questionId]);

    useEffect(() => {
        if (answer === undefined) {
            message.warning(I18n.t("makeChoice"));
        }
    }, [answer]);

    return (
        <div className={"nps-template"}>
            <div>
                {npsParams
                    ?.map((it) => it)
                    ?.sort((it) => (it?.grade === GradeEnum.MAX_GRADE ? 1 : -1))
                    ?.map((it) => (
                        <div key={it.grade} className={"grade-text"}>
              <span style={{ fontFamily: question?.surveyDecor?.textFont }}>
                <Translate value={it?.grade === GradeEnum.MAX_GRADE ? "max" : "min"} />
              </span>
                            <span style={{ fontFamily: question?.surveyDecor?.textFont }}>
                {getCurrentTextTranslation(it?.textTranslations)}
              </span>
                        </div>
                    ))}
            </div>
            {isType(NpsTypeEnum.SLIDER) ? (
                <Slider
                    dots
                    min={1}
                    value={answer}
                    tooltip={answer !== undefined ? { open: true } : { open: false }}
                    max={npsInfoParams?.maxGrade}
                    onChange={(val) => onChange(String(val))}
                />
            ) : isType(NpsTypeEnum.RATING) ? (
                <div className={"rating-container"}>
                    {npsInfoParams?.icon && npsInfoParams?.maxGrade && (
                        <Rating onChange={onChange} iconType={npsInfoParams.icon} maxValue={npsInfoParams.maxGrade} />
                    )}
                </div>
            ) : isType(NpsTypeEnum.DROPDOWN) ? (
                <Select
                    size={"large"}
                    onChange={onChange}
                    value={answer ? String(answer) : undefined}
                    placeholder={<Translate value={"select"} />}
                    style={{ width: "98%", fontFamily: question?.surveyDecor?.textFont }}
                >
                    {Array.from(Array(npsInfoParams?.maxGrade).keys())
                        .reverse()
                        .map((it) => (
                            <Select.Option value={String(++it)} key={it}>
                                {it}
                            </Select.Option>
                        ))}
                </Select>
            ) : isType(NpsTypeEnum.RADIO_BUTTONS) ? (
                <Radio.Group value={answer} onChange={({ target }) => onChange(target.value)} size={"large"}>
                    <Space direction="vertical">
                        {Array.from(Array(npsInfoParams?.maxGrade).keys())
                            .reverse()
                            .map((it) => (
                                <Radio value={++it} key={it}>
                                    <span style={{ marginLeft: 25, fontFamily: question?.surveyDecor?.textFont }}>{it}</span>
                                </Radio>
                            ))}
                    </Space>
                </Radio.Group>
            ) : (
                <></>
            )}
        </div>
    );
};

export default NpsComponent;
